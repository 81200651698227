import cs from "classnames";
import React, { memo, useState } from "react";
import "./client-faq-container.scss";

import useSiteMetadata from "../../api/graphql/siteMetadata";

const AGENT_NAME_PLACEHOLDER = "###title###";

const clientFAQs = [
  {
    id: 1,
    title: "howToGetStarted",
    question: "How do I get started with HomeLight Trade-In?",
    answer: `You’ll work with ${AGENT_NAME_PLACEHOLDER} to fill out your HomeLight Trade-In application and a HomeLight representative will be in touch to discuss your home buying goals.
    <br/>
    <br/>
    You can also get a head start on the home buying process by
    completing your mortgage application on 
    <a href="https://www.homelight.com/home-loans" target="_blank">HomeLight Home Loans</a>.
    <br/>`,
  },
  {
    id: 2,
    title: "whatDoesItCost",
    question: "What does it cost to use HomeLight Trade-In?",
    answer: `<p>
    HomeLight charges a fee to purchase, own, and sell your current home. The fee is dependent on your market, the lender you use, and the number of days HomeLight owns your home. All fees are calculated off of the final selling price of your current home. Additional costs may apply based on local tax laws.

  </p>
  <p>
    Additional costs may apply based on local tax laws.
    <br />
    <br />
    California and Colorado fee schedule:
    <table>
      <thead>
        <tr>
          <th />
          <th>First 60 days HomeLight owns the home</th>
          <th>Every 30 days thereafter</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>When using HomeLight Home Loans</td>
          <td>1.5%</td>
          <td>0.5%, prorated by day</td>
        </tr>
        <tr>
          <td>When using another lender</td>
          <td>3.5%</td>
          <td>0.5%, prorated by day</td>
        </tr>
      </tbody>
    </table>
  </p>
  <br />
  <p>
    Texas fee schedule:
    <table>
      <thead>
        <tr>
          <th />
          <th>First 60 days HomeLight owns the home</th>
          <th>Every 30 days thereafter</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>When using HomeLight Home Loans</td>
          <td>2.0%</td>
          <td>1.0%, prorated by day</td>
        </tr>
        <tr>
          <td>When using another lender</td>
          <td>4.0%</td>
          <td>1.0%, prorated by day</td>
        </tr>
      </tbody>
    </table>
  </p>

  <p>
    Email <a href="mailto:cashclose@homelight.com">cashclose@homelight.com</a> for more details.
  </p>`,
  },
  {
    id: 3,
    title: "howIsMyHomeSold",
    question: "How is my home sold with HomeLight Trade-In?",
    answer: `${AGENT_NAME_PLACEHOLDER} and <a href="https://www.homelight.com/" target="_blank">HomeLight</a> work together throughout the listing and sale process. Our goal is for you to get into the home of your dreams while receiving full market value for your current home. If your home sells for more than the price we paid for it, we’ll give you the additional cash minus selling costs and program fees.`,
  },
  {
    id: 4,
    title: "stillWithAgents",
    question: `Can I still work with ${AGENT_NAME_PLACEHOLDER}?`,
    answer: `Yes! HomeLight is proud to work with ${AGENT_NAME_PLACEHOLDER} and they’ll give you top notch council and guidance throughout the process.`,
  },
  {
    id: 5,
    title: "isHomeLightAvailable",
    question: "Is HomeLight Trade-In available for my house?",
    answer: `<a href="https://www.homelight.com/trade-in" target="_blank">HomeLight Trade-In</a> is currently available in California, Colorado and Texas but things like where you’re located, 
    the size, and condition of your will determine eligibility. Enter your information ###contact-form### to be notified when HomeLight Trade-In launches in your area.`,
  },
];

const ClientFaqContainer = (props) => {
  let { openModalHandler } = props;

  const title = useSiteMetadata().title;

  const [openFAQ, setOpenFAQ] = useState("");

  const toggleFAQ = (value) => setOpenFAQ(openFAQ === value ? "" : value);

  const renderItem = (item) => {
    if (item.id === 5) {
      let parts = item.answer.split("###contact-form###");

      return (
        <div>
          <span
            className="answer-main-content"
            dangerouslySetInnerHTML={{
              __html: parts[0].replace(AGENT_NAME_PLACEHOLDER, title),
            }}
          />
          <a
            href="#"
            className="answer-pre-content"
            onClick={(e) => {
              e.stopPropagation();
              openModalHandler(true);
            }}
          >
            here
          </a>
          <span
            className="answer-main-content"
            dangerouslySetInnerHTML={{
              __html: parts[1].replace(AGENT_NAME_PLACEHOLDER, title),
            }}
          />
        </div>
      );
    } else {
      return (
        <div>
          {item.id === 1 && (
            <a
              href="#"
              className="answer-pre-content"
              onClick={(e) => {
                e.stopPropagation();
                openModalHandler(true);
              }}
            >
              Apply here.&nbsp;
            </a>
          )}
          <span
            className="answer-main-content"
            dangerouslySetInnerHTML={{
              __html: item.answer.replace(AGENT_NAME_PLACEHOLDER, title),
            }}
          />
        </div>
      );
    }
  };

  return (
    <div className={cs("on-client-faq")}>
      <div className="faq-component">
        <h2>FAQs</h2>
        <div
          className="faq"
          style={{ borderTop: "1px solid rgb(221, 221, 221)" }}
        >
          {clientFAQs.map((item) => (
            <div className="accordion" key={`faq-${item.id}`}>
              <div
                className="accordion-top"
                onClick={() => toggleFAQ(item.title)}
              >
                {item.question.replace(AGENT_NAME_PLACEHOLDER, title)}
                <div className="expand-icon">
                  <svg
                    className={`chevron-icon-component ${
                      openFAQ === item.title ? "" : "right"
                    }`}
                    viewBox="0 0 13 8"
                  >
                    <path d="M5.66014276,7.16797312 L0.355467902,1.86329827 C0.227863901,1.73569426 0.164062109,1.58074699 0.164062109,1.39845562 C0.164062109,1.21616425 0.227863901,1.06121698 0.355467902,0.933612982 L0.957028968,0.332051916 C1.08463297,0.204447915 1.23958024,0.136088703 1.42187161,0.12697428 C1.60416298,0.117859857 1.75911025,0.17710423 1.88671425,0.304708231 L6.1249854,4.54297938 L10.3632565,0.304708231 C10.4908605,0.17710423 10.6458078,0.117859857 10.8280992,0.12697428 C11.0103906,0.136088703 11.1653378,0.204447915 11.2929418,0.332051916 L11.8945029,0.933612982 C12.0221069,1.06121698 12.0859087,1.21616425 12.0859087,1.39845562 C12.0859087,1.58074699 12.0221069,1.73569426 11.8945029,1.86329827 L6.58982804,7.16797312 C6.46222404,7.31380596 6.30727677,7.3867226 6.1249854,7.3867226 C5.94269403,7.3867226 5.78774676,7.31380596 5.66014276,7.16797312 Z"></path>
                  </svg>
                </div>
              </div>
              <div
                className={`accordion-details ${
                  openFAQ === item.title ? "details" : "details-hidden"
                }`}
              >
                <p>{renderItem(item)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(ClientFaqContainer);
