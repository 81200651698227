import cs from 'classnames';
import React, { memo } from 'react';
import './how-it-works-container.scss';

import useSiteMetadata from "../../api/graphql/siteMetadata";

const HowItWorksContainer = (props) => {

  const title = useSiteMetadata().title;

  return (
    <div className={cs('on-how-it-works')}>
      <div className="cash-offer-process section">
        <div className="content">
            <h2>How HomeLight Trade-In works</h2>
            <div className="process-step">
              <div className="step">
                  <div className="icon">
                    <svg className="document-icon" viewBox="0 0 96 102.29">
                      <g id="Layer_2" data-name="Layer 2">
                          <g id="guaranteed">
                            <circle cx="48" cy="48" r="48" fill="#e8eef9"></circle>
                            <polygon fill="#fff" points="77.8 101.88 18.2 101.88 18.2 19.16 65.13 19.16 77.8 31.86 77.8 101.88"></polygon>
                            <polygon fill="#bbd3ea" points="77.8 31.86 65.13 31.86 65.13 19.16 77.8 31.86"></polygon>
                            <rect fill="#bbd3ea" x="22.79" y="44.43" width="45.84" height="2.3"></rect>
                            <rect fill="#bbd3ea" x="22.79" y="51.33" width="45.84" height="2.3"></rect>
                            <rect fill="#bbd3ea" x="22.79" y="58.22" width="22.92" height="2.3"></rect>
                            <rect fill="#bbd3ea" x="22.79" y="76.61" width="22.92" height="2.3"></rect>
                            <path fill="#273653" d="M23.56,92.35a.81.81,0,0,1-.34-.08c-.83-.37-.53-1.33-.17-2.44a6.31,6.31,0,0,0,.49-3,.71.71,0,1,1,1.36-.39,6.05,6.05,0,0,1-.34,3.31c.46-.52,1-1.12,1.41-1.63,3.16-3.73,4.45-5.08,5.51-4.49s.54,2.44-.2,4.76c-.21.65-.49,1.52-.62,2.12a28.09,28.09,0,0,0,2-2.15c1.69-1.91,2.53-2.87,3.36-2.33s.53,1.76.24,3.2c0,.22-.1.5-.14.75l.12-.08a6.6,6.6,0,0,1,1.93-.86,6.19,6.19,0,0,1,3.53.24.7.7,0,0,1,.41.91.71.71,0,0,1-.92.41,4.79,4.79,0,0,0-2.72-.17,5.39,5.39,0,0,0-1.5.7c-.66.39-1.48.87-2.08.22-.42-.45-.27-1.18,0-2.4.06-.3.15-.74.21-1.12-.42.42-.94,1-1.34,1.47-2,2.26-3.09,3.41-4,2.87s-.49-2,.21-4.21a18.54,18.54,0,0,0,.76-2.83,35.21,35.21,0,0,0-3.63,3.91C25,91.46,24.22,92.35,23.56,92.35Z"></path>
                            <path fill="#273653" d="M28.9,35.15a1.76,1.76,0,0,1-.64,1.43,3.19,3.19,0,0,1-1.8.65v1.15h-.77V37.25a6.24,6.24,0,0,1-2.39-.48V35.29a5.43,5.43,0,0,0,1.17.43,6.2,6.2,0,0,0,1.22.22V34.2l-.37-.14a4.12,4.12,0,0,1-1.57-1,1.81,1.81,0,0,1-.47-1.27,1.66,1.66,0,0,1,.64-1.33,3.24,3.24,0,0,1,1.77-.64V29h.77v.83a6.52,6.52,0,0,1,2.31.51l-.52,1.31a6.24,6.24,0,0,0-1.79-.43v1.65a8.54,8.54,0,0,1,1.55.73,2.17,2.17,0,0,1,.68.67A1.74,1.74,0,0,1,28.9,35.15ZM25,31.84a.55.55,0,0,0,.17.41,2.45,2.45,0,0,0,.55.33V31.26C25.21,31.33,25,31.53,25,31.84Zm2.25,3.4a.53.53,0,0,0-.19-.4,2.38,2.38,0,0,0-.57-.33V35.9C27,35.81,27.22,35.59,27.22,35.24Z"></path>
                            <path fill="#273653" d="M35.7,36h-1v1.69H33V36H29.53V34.81l3.59-5.28h1.59v5.14h1ZM33,34.67V33.29c0-.24,0-.57,0-1s0-.7,0-.77h0a5.7,5.7,0,0,1-.5.9l-1.5,2.26Z"></path>
                            <path fill="#273653" d="M42,33.62a5.75,5.75,0,0,1-.7,3.17,2.44,2.44,0,0,1-2.17,1A2.4,2.4,0,0,1,37,36.76a5.61,5.61,0,0,1-.72-3.14,5.71,5.71,0,0,1,.7-3.19,2.71,2.71,0,0,1,4.29.05A5.54,5.54,0,0,1,42,33.62Zm-4,0a6.22,6.22,0,0,0,.26,2.16.9.9,0,0,0,.87.65.91.91,0,0,0,.88-.66,6.48,6.48,0,0,0,.26-2.15A6.2,6.2,0,0,0,40,31.46a.9.9,0,0,0-1.74,0A6.25,6.25,0,0,0,38,33.62Z"></path>
                            <path fill="#273653" d="M48.55,33.62a5.66,5.66,0,0,1-.7,3.17,2.44,2.44,0,0,1-2.17,1,2.4,2.4,0,0,1-2.13-1.06,5.61,5.61,0,0,1-.72-3.14,5.71,5.71,0,0,1,.7-3.19,2.71,2.71,0,0,1,4.29.05A5.54,5.54,0,0,1,48.55,33.62Zm-4,0a6.47,6.47,0,0,0,.26,2.16.9.9,0,0,0,.87.65.91.91,0,0,0,.88-.66,6.17,6.17,0,0,0,.27-2.15,5.92,5.92,0,0,0-.28-2.16.9.9,0,0,0-1.74,0A6.51,6.51,0,0,0,44.55,33.62Z"></path>
                            <path fill="#273653" d="M51.49,34.31l.75-1L54,31.46h1.92l-2.48,2.71,2.63,3.54h-2l-1.8-2.53-.73.58v2H49.85V29h1.71v3.88l-.09,1.42Z"></path>
                            <path d="M84.28,84.42c-.45-1.39,0-3.23-.85-4.41s-2.75-1.32-3.92-2.16-1.89-2.61-3.27-3.06-3,.56-4.45.56-3.11-1-4.45-.56S65.22,77,64.07,77.85,61,78.85,60.15,80s-.4,3-.86,4.4S57.4,87,57.4,88.48s1.47,2.72,1.89,4.06,0,3.23.85,4.41,2.76,1.32,3.93,2.16,1.88,2.61,3.27,3.07,3-.56,4.45-.56,3.1,1,4.45.56,2.11-2.22,3.27-3.07,3.07-1,3.92-2.16.4-3,.85-4.41,1.9-2.57,1.9-4.06S84.71,85.76,84.28,84.42Z" fill="#46b6ff"></path>
                            <path fill="#fff" d="M69.31,94l-5-5a.77.77,0,0,1,0-1.09l1.09-1.1a.77.77,0,0,1,1.09,0l3.38,3.38L77.1,83a.76.76,0,0,1,1.09,0l1.09,1.09a.77.77,0,0,1,0,1.09L70.4,94a.77.77,0,0,1-1.09,0Z"></path>
                          </g>
                      </g>
                    </svg>
                  </div>
                  <div className="desc">
                    <h3>Get HomeLight's purchase price</h3>
                    <p>HomeLight will work with {title} to make a competitive cash offer on your current home. That's the price HomeLight will pay you for your home as soon as you're ready to move into your new home.</p>
                  </div>
              </div>
              <div className="step">
                  <div className="icon">
                    <svg className="dream-home-money-icon" viewBox="0 0 97.88 103.49">
                      <g id="Layer_2" data-name="Layer 2">
                          <g id="strong-offer">
                            <circle fill="#e8eef9" cx="48" cy="55.49" r="48"></circle>
                            <rect fill="#46b6ff" x="14.87" y="5.01" width="32" height="15.2" transform="translate(6.18 -9.8) rotate(20)"></rect>
                            <path fill="#008ce7" d="M42.41,23.59,15,13.61l4.36-12,27.43,10ZM16.26,13l25.55,9.3,3.68-10.1L19.94,2.91Z"></path>
                            <circle fill="#008ce7" cx="30.87" cy="12.61" r="3.98"></circle>
                            <path fill="#008ce7" d="M19.64,2.27l3,1.1a3.19,3.19,0,0,1-4.09,1.91Z"></path>
                            <path fill="#008ce7" d="M15.62,13.31l1.1-3a3.21,3.21,0,0,1,1.91,4.1Z"></path>
                            <path fill="#008ce7" d="M46.13,11.91l-1.1,3a3.21,3.21,0,0,1-1.91-4.1Z"></path>
                            <path fill="#008ce7" d="M42.11,23l-3-1.09A3.19,3.19,0,0,1,43.2,20Z"></path>
                            <rect fill="#46b6ff" x="27.2" y="28.08" width="32" height="15.2" transform="translate(-9.6 16.93) rotate(-20)"></rect>
                            <path fill="#008ce7" d="M31.67,46.66l-4.36-12,27.43-10,4.36,12ZM28.59,35.28l3.68,10.1,25.55-9.3L54.14,26Z"></path>
                            <circle fill="#008ce7" cx="43.2" cy="35.68" r="3.98"></circle>
                            <path fill="#008ce7" d="M28,35l3-1.09A3.2,3.2,0,0,1,29,38Z"></path>
                            <path fill="#008ce7" d="M32,46l-1.1-3A3.19,3.19,0,0,1,35,44.93Z"></path>
                            <path fill="#008ce7" d="M54.44,25.34l1.09,3a3.21,3.21,0,0,1-4.1-1.91Z"></path>
                            <path fill="#008ce7" d="M58.46,36.38l-3,1.09a3.19,3.19,0,0,1,1.91-4.09Z"></path>
                            <polygon fill="#fff" points="68.54 83.04 31.4 83.04 31.4 57.1 49.97 38.31 68.54 57.1 68.54 83.04"></polygon>
                            <polygon fill="#bbd3ea" points="97.88 57.1 68.54 57.1 49.97 38.31 77.88 38.31 97.88 57.1"></polygon>
                            <rect fill="#e8eef9" x="45.75" y="66.38" width="8.44" height="16.65"></rect>
                            <rect fill="#e8eef9" x="68.54" y="57.15" width="29.34" height="25.85"></rect>
                            <rect fill="#bbd3ea" x="79.77" y="66.38" width="8.44" height="8.44"></rect>
                            <circle fill="#bbd3ea" cx="20.3" cy="74.18" r="3.58"></circle>
                            <polygon fill="#fff" points="23.07 83.04 17.54 83.04 16.72 77.13 23.88 77.13 23.07 83.04"></polygon>
                            <path fill="#bbd3ea" d="M73.92,79.56A5.38,5.38,0,1,0,64.43,83h8.23A5.36,5.36,0,0,0,73.92,79.56Z"></path>
                            <path fill="#bbd3ea" d="M36.78,79.56A5.39,5.39,0,1,0,27.28,83h8.23A5.37,5.37,0,0,0,36.78,79.56Z"></path>
                            <rect fill="#46b6ff" x="14.87" y="51.13" width="32" height="15.2" transform="translate(21.95 -7.02) rotate(20)"></rect>
                            <path fill="#008ce7" d="M42.41,69.71,15,59.73l4.36-12,27.43,10ZM16.26,59.13l25.55,9.3,3.68-10.1L19.94,49Z"></path>
                            <circle fill="#008ce7" cx="30.87" cy="58.73" r="3.98"></circle>
                            <path fill="#008ce7" d="M19.64,48.39l3,1.1a3.2,3.2,0,0,1-4.09,1.91Z"></path>
                            <path fill="#008ce7" d="M15.62,59.43l1.1-3a3.2,3.2,0,0,1,1.91,4.09Z"></path>
                            <path fill="#008ce7" d="M46.13,58,45,61a3.21,3.21,0,0,1-1.91-4.1Z"></path>
                            <path fill="#008ce7" d="M42.11,69.07l-3-1.09a3.18,3.18,0,0,1,4.09-1.91Z"></path>
                          </g>
                      </g>
                    </svg>
                  </div>
                  <div className="desc">
                    <h3>Make a strong offer on your new home</h3>
                    <p>When you’ve found the home of your dreams, you can make an offer knowing HomeLight will buy your current house. This means you’re more likely to close and can do so on your own timeline.</p>
                  </div>
              </div>
              <div className="step">
                  <div className="icon">
                      <svg className="keys-icon" viewBox="0 0 96 97.76">
                        <g id="Layer_2" data-name="Layer 2">
                            <g id="move-in">
                              <circle cx="48" cy="48" r="48" fill="#e8eef9"></circle>
                              <rect fill="#bbd3ea" x="58.06" y="1.36" width="30" height="59.72"></rect>
                              <polygon points="58.06 61.08 79.06 73.08 79.06 13.36 58.06 1.36 58.06 61.08" fill="#fff"></polygon>
                              <rect fill="#bbd3ea" x="73.16" y="38.6" width="2.62" height="7.45"></rect>
                              <path fill="#9bb0c9" d="M19.68,44.14a1.49,1.49,0,0,1-1.41-1L17,39.58a11.83,11.83,0,0,1,22.24-8.1l1.3,3.58a1.5,1.5,0,0,1-2.82,1l-1.3-3.58a8.83,8.83,0,0,0-16.6,6l1.3,3.58a1.5,1.5,0,0,1-.9,1.92A1.52,1.52,0,0,1,19.68,44.14Z"></path>
                              <path fill="#0c6eba" d="M50.37,67.19l5.37.47-.45,5.4,5.38.47L60.2,78.9l2.45,2.92L70.2,82l1.14-7.47L54.94,55a16,16,0,1,0-8.7,7.29Z"></path>
                              <path d="M24.47,46.53a3.26,3.26,0,1,1-4.17-1.94A3.26,3.26,0,0,1,24.47,46.53Zm1.61,30.53L31,79.34,28.7,84.26l4.89,2.28-2.28,4.89L32.61,95l7,2.75,3.62-6.63-8.72-24A16,16,0,1,0,23.88,71Z" fill="#008ce7"></path>
                              <polygon fill="#0c6eba" points="24.47 46.53 21.88 39.24 19.97 39.63 21.6 44.39 22.37 44.54 23.16 44.91 23.84 45.48 24.47 46.53"></polygon>
                              <path fill="#9bb0c9" d="M20.29,46.09a1.49,1.49,0,0,1-1.41-1L17,39.56a1.5,1.5,0,0,1,2.83-1l1.92,5.52A1.5,1.5,0,0,1,20.79,46,1.47,1.47,0,0,1,20.29,46.09Z"></path>
                            </g>
                        </g>
                      </svg>
                  </div>
                  <div className="desc">
                    <h3>Move in on your schedule</h3>
                    <p>You control when you move. Once your offer is accepted, everything comes together in days. You won’t have to race against the clock, move into a temporary residence, or carry two mortgages. HomeLight buys your current home at the HomeLight Purchase Price and you get the cash to close on your dream home whenever you’re ready.</p>
                  </div>
              </div>
              <div className="step">
                  <div className="icon">
                    <svg className="money-icon" viewBox="0 0 100.07 96">
                      <g id="Layer_2" data-name="Layer 2">
                          <g id="value">
                            <circle fill="#e8eef9" cx="52.07" cy="48" r="48"></circle>
                            <polygon fill="#fff" points="4.07 35.23 0 71.84 36.3 81.22 62.58 47.24 33.48 26.79 4.07 35.23"></polygon>
                            <path fill="#e8eef9" d="M29.52,48C25,50.06,14.78,49.87,14.78,49.87V39.92l23.6-1.83S34,45.84,29.52,48Z"></path>
                            <rect fill="#008ce7" x="13.23" y="30.57" width="76.09" height="36.14" transform="translate(-19.08 55.43) rotate(-49)"></rect>
                            <rect fill="#008ce7" x="16.03" y="29.93" width="76.09" height="36.14" transform="translate(-17.91 54.81) rotate(-47)"></rect>
                            <rect x="18.84" y="29.4" width="76.09" height="36.14" transform="translate(-16.9 54.13) rotate(-45)" fill="#46b6ff"></rect>
                            <path fill="#008ce7" d="M43.07,82.45,21.9,61.29,70.71,12.48,91.88,33.65ZM24.73,61.29,43.07,79.63l46-46L70.71,15.31Z"></path>
                            <circle fill="#008ce7" cx="56.89" cy="47.47" r="9.47"></circle>
                            <path fill="#008ce7" d="M23.32,61.29l5.37-5.38a7.6,7.6,0,0,1,0,10.75Z"></path>
                            <path fill="#008ce7" d="M43.07,81l-5.38-5.37a7.6,7.6,0,0,1,10.75,0Z"></path>
                            <path fill="#008ce7" d="M70.71,13.89l5.38,5.38a7.6,7.6,0,0,1-10.75,0Z"></path>
                            <path fill="#008ce7" d="M90.46,33.65,85.09,39a7.6,7.6,0,0,1,0-10.75Z"></path>
                            <path fill="#fff" d="M39.58,81.31c1,.91,8.07,4.52,9.57,4.65s3.82-.42,4.58-2.25a4.12,4.12,0,0,0-1.06-4.93c-1.21-1-8.37-3-10-3.19a4.32,4.32,0,0,0-3.94,1.71A3.49,3.49,0,0,0,39.58,81.31Z"></path>
                            <path fill="#fff" d="M48.81,74.4c1.11,1,7.57,4.47,9.18,4.61s4.09-.45,4.9-2.42a4.38,4.38,0,0,0-1.13-5.27c-1.3-1-7.9-2.8-9.63-3a4.66,4.66,0,0,0-4.22,1.83A3.75,3.75,0,0,0,48.81,74.4Z"></path>
                            <path fill="#fff" d="M68.43,58.07c1.1,1,5.41,3.53,7,3.67s4.09-.45,4.9-2.41a4.42,4.42,0,0,0-1.13-5.28c-1.31-1-5.74-1.86-7.47-2.11a4.68,4.68,0,0,0-4.23,1.83A3.74,3.74,0,0,0,68.43,58.07Z"></path>
                            <path fill="#fff" d="M59.21,66.44c1.1,1,5.4,3.53,7,3.67s4.08-.45,4.9-2.41A4.41,4.41,0,0,0,70,62.43c-1.31-1-5.74-1.86-7.47-2.12a4.66,4.66,0,0,0-4.23,1.84A3.72,3.72,0,0,0,59.21,66.44Z"></path>
                            <path fill="#fff" d="M21.35,48.65c4.51-3.09,9.71-9.81,12.25-10.13,2.81-.36,13.66,4,16.19,4.6a19.74,19.74,0,0,0,7,.37c1.5-.28,2.78-1.34,2.86-2.06.17-1.54-21.85-14.08-26.21-14.64S4.07,35.24,4.07,35.24L8.68,55S16.73,51.83,21.35,48.65Z"></path>
                          </g>
                      </g>
                    </svg>
                  </div>
                  <div className="desc">
                    <h3>Get full market value when we sell your home</h3>
                    <p>{title} goes to work and lists your current home for sale. If your home sells for more than the HomeLight Purchase Price you get the additional cash minus selling costs and program fees.</p>
                  </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default memo(HowItWorksContainer);
