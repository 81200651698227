import cs from 'classnames';
import React, { memo } from 'react';
import './call-to-action-container.scss';

const HowItWorksContainer = (props) => {
  let {openModalHandler} = props;

  return (
    <div className={cs('on-call-to-action')}>
        <div className="section-cta section">
          <div className="content">
              <h2>Curious how much we'll offer for your home?</h2>
              <a className="button" onClick={() => openModalHandler(true)}>Get your Offer</a>
          </div>
        </div>
    </div>
  );
};

export default memo(HowItWorksContainer);
