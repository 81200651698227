import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  HeaderContainer,
  FooterContainer,
  HeroContainer,
  HowItWorksContainer,
  CallToActionContainer,
  TestimonialsContainer,
  WhyHomelightContainer,
  ClientFaqContainer,
  ContactModalContainer,
} from "@containers";

import { submitContactForm } from "../api/contact-form/contact-api";

import "./common.scss";
import "./layout.scss";

const Layout = ({ children, location }) => {
  const [contactModalOpen, setContactModalOpen] = useState(false);

  useEffect(() => {
    if (contactModalOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [contactModalOpen]);

  return (
    <div className="layout-container">
      <HeaderContainer location={location} />
      <div className="on-main-container">
        <ContactModalContainer
          isOpen={contactModalOpen}
          openModalHandler={setContactModalOpen}
          submitContactForm={submitContactForm}
          location={location}
        />
        <HeroContainer openModalHandler={setContactModalOpen} />
        <HowItWorksContainer />
        <CallToActionContainer openModalHandler={setContactModalOpen} />
        <TestimonialsContainer />
        <WhyHomelightContainer />
        <ClientFaqContainer openModalHandler={setContactModalOpen} />
        {children}
      </div>
      <FooterContainer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default memo(Layout);
