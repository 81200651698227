import cs from "classnames";
import PropTypes from "prop-types";
import React, { memo, useEffect, useState, useRef } from "react";
import "./header-container.scss";

function closeModalOutsideTrigger(ref, setActive) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setActive(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
}
const HeaderContainer = (props) => {
  const [isActive, setActive] = useState(false);
  const [videosToggle, setVideosToggle] = useState(false);
  const [communitiesToggle, setCommunitiesToggle] = useState(false);
  const [propertiesToggle, setPropertiesToggle] = useState(false);

  const toggleVideos = () => {
    setVideosToggle(!videosToggle);
  };

  const toggleCommunities = () => {
    setCommunitiesToggle(!communitiesToggle);
  };

  const toggleProperties = () => {
    setPropertiesToggle(!propertiesToggle);
  };

  const toggleClass = () => {
    setActive(!isActive);
  };

  const wrapperRef = useRef(null);
  closeModalOutsideTrigger(wrapperRef, setActive);
  return (
    <div className={cs("on-title-header")} ref={wrapperRef}>
      <div id="header" className="wrap">
        <div className="header_container">
          <div className="title">
            <a href="http://www.andrewdinsky.com" className="site-name">
              {" "}
              <div className="textwidget">
                <img
                  alt="ANDREW DINSKY - Andrew Dinsky"
                  title="ANDREW DINSKY"
                  data-src="[blogurl]/wp-content/uploads/2017/09/header3-e1504733948415.png"
                  className="logo-img lazyload"
                  src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                />
                <noscript>
                  <img
                    src="[blogurl]/wp-content/uploads/2017/09/header3-e1504733948415.png"
                    alt="ANDREW DINSKY - Andrew Dinsky"
                    title="ANDREW DINSKY"
                    className="logo-img"
                  />
                </noscript>
                <img
                  width="414"
                  height="69"
                  alt="ANDREW DINSKY - Andrew Dinsky"
                  title="ANDREW DINSKY"
                  data-src="http://www.andrewdinsky.com/wp-content/uploads/2018/05/DINSKYTEAMLOGO2018-1-e1525201513551.png"
                  className="logo-img lazyloaded"
                  src={require("../../images/DINSKYTEAMLOGO2018.png")}
                />
                <noscript>
                  <img
                    src={require("../../images/DINSKYTEAMLOGO2018.png")}
                    width="414"
                    height="69"
                    alt="ANDREW DINSKY - Andrew Dinsky"
                    title="ANDREW DINSKY"
                    className="logo-img"
                  />
                </noscript>
              </div>
            </a>
          </div>
          <div className="navigation">
            <div className="menu-main_nav-container">
              <ul id="nav" className="menu">
                <li
                  id="menu-item-2495"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2495"
                >
                  <a href="http://www.andrewdinsky.com/meettheteam/">About</a>
                </li>
                <li
                  id="menu-item-10"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-10"
                >
                  <a href="#">Properties</a>
                  <ul className="sub-menu">
                    <li
                      id="menu-item-60"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-60"
                    >
                      <a href="http://www.andrewdinsky.com/properties/property-search/">
                        Property Search
                      </a>
                    </li>
                    <li
                      id="menu-item-284"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-284"
                    >
                      <a href="http://www.andrewdinsky.com/homes-for-sale-featured/">
                        Current Listings
                      </a>
                    </li>
                    <li
                      id="menu-item-1246"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1246"
                    >
                      <a href="http://www.andrewdinsky.com/homes-for-sale-toppicks/Open-Houses/946768">
                        Open Houses
                      </a>
                    </li>
                    <li
                      id="menu-item-290"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-290"
                    >
                      <a href="http://www.andrewdinsky.com/sold-featured-listing/?sortBy=soldDate">
                        Sold Properties
                      </a>
                    </li>
                    <li
                      id="menu-item-182"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-182"
                    >
                      <a href="http://www.andrewdinsky.com/properties/pocket-listings/">
                        Pocket Listings
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  id="menu-item-1452"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1452"
                >
                  <a href="http://www.andrewdinsky.com/communities/">
                    Communities
                  </a>
                  <ul className="sub-menu">
                    <li
                      id="menu-item-1458"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1458"
                    >
                      <a href="http://www.andrewdinsky.com/valleyvillage/">
                        Valley Village
                      </a>
                    </li>
                    <li
                      id="menu-item-1457"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1457"
                    >
                      <a href="http://www.andrewdinsky.com/shermanoaks/">
                        Sherman Oaks
                      </a>
                    </li>
                    <li
                      id="menu-item-1512"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1512"
                    >
                      <a href="http://www.andrewdinsky.com/studiocity/">
                        Studio City
                      </a>
                    </li>
                    <li
                      id="menu-item-1462"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1462"
                    >
                      <a href="http://www.andrewdinsky.com/tolucalake/">
                        Toluca Lake
                      </a>
                    </li>
                    <li
                      id="menu-item-1471"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1471"
                    >
                      <a href="http://www.andrewdinsky.com/encino/">Encino</a>
                    </li>
                    <li
                      id="menu-item-1463"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1463"
                    >
                      <a href="http://www.andrewdinsky.com/westhollywood/">
                        West Hollywood
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  id="menu-item-32"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-32"
                >
                  <a href="http://www.andrewdinsky.com/about__trashed/reviews/">
                    Testimonials
                  </a>
                </li>
                <li
                  id="menu-item-409"
                  className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-409"
                >
                  <a href="http://www.andrewdinsky.com/category/blog/">Blog</a>
                </li>
                <li
                  id="menu-item-638"
                  className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-has-children menu-item-638"
                >
                  <a href="http://www.andrewdinsky.com/#" aria-current="page">
                    Videos
                  </a>
                  <ul className="sub-menu">
                    <li
                      id="menu-item-639"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-639"
                    >
                      <a href="http://www.andrewdinsky.com/top-3-picks-of-the-week/">
                        Top 3 Picks
                      </a>
                    </li>
                    <li
                      id="menu-item-635"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-635"
                    >
                      <a href="http://www.andrewdinsky.com/property-videos/">
                        Property Tours
                      </a>
                    </li>
                    <li
                      id="menu-item-2724"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2724"
                    >
                      <a href="http://www.andrewdinsky.com/3d-virtual-tours/">
                        3D Virtual Tours
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  id="menu-item-23"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-23"
                >
                  <a href="http://www.andrewdinsky.com/contact/">Contact</a>
                </li>
                <li
                  id="menu-item-53"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-53"
                >
                  <div className="header-smi">
                    <a
                      href="https://www.facebook.com/thedinskyteam"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        width="18"
                        height="18"
                        alt="facebook - Andrew Dinsky"
                        data-src="http://www.andrewdinsky.com/wp-content/uploads/2015/05/facebook.png"
                        className=" lazyloaded"
                        src={require("../../images/facebook.png")}
                      />
                      <noscript>
                        <img
                          src={require("../../images/facebook.png")}
                          width="18"
                          height="18"
                          alt="facebook - Andrew Dinsky"
                        />
                      </noscript>
                    </a>

                    <a
                      href="https://twitter.com/andrewdinsky"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        width="18"
                        height="18"
                        alt="twitter - Andrew Dinsky"
                        data-src="http://www.andrewdinsky.com/wp-content/uploads/2015/05/twitter.png"
                        className=" lazyloaded"
                        src={require("../../images/twitter.png")}
                      />
                      <noscript>
                        <img
                          src={require("../../images/twitter.png")}
                          width="18"
                          height="18"
                          alt="twitter - Andrew Dinsky"
                        />
                      </noscript>
                    </a>

                    <a
                      href="https://www.linkedin.com/company/thedinskyteam/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        width="18"
                        height="18"
                        alt="linkedin - Andrew Dinsky"
                        data-src="http://www.andrewdinsky.com/wp-content/uploads/2015/05/linkedin.png"
                        className=" lazyloaded"
                        src={require("../../images/linkedin.png")}
                      />
                      <noscript>
                        <img
                          src={require("../../images/linkedin.png")}
                          width="18"
                          height="18"
                          alt="linkedin - Andrew Dinsky"
                        />
                      </noscript>
                    </a>

                    <a
                      href="https://www.instagram.com/thedinskyteam/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        width="18"
                        height="18"
                        alt="Instagram"
                        data-src="http://www.andrewdinsky.com/wp-content/uploads/2015/12/ins.png"
                        className=" lazyloaded"
                        src={require("../../images/ins.png")}
                      />
                      <noscript>
                        <img
                          src={require("../../images/ins.png")}
                          width="18"
                          height="18"
                          alt="Instagram"
                        />
                      </noscript>
                    </a>
                    <a
                      href="https://www.youtube.com/andrewdinsky/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        width="25"
                        height="18"
                        style={{ marginLeft: "5px" }}
                        alt="Youtube"
                        data-src="http://www.andrewdinsky.com/wp-content/uploads/2019/08/yt.png"
                        className=" lazyloaded"
                        src={require("../../images/yt.png")}
                      />
                      <noscript>
                        <img
                          src={require("../../images/yt.png")}
                          width="25"
                          height="18"
                          style={{ marginLeft: "5px" }}
                          alt="Youtube"
                        />
                      </noscript>
                    </a>
                  </div>

                  <div className="side-nav">
                    {" "}
                    <div className="textwidget">
                      <ul>
                        <li>
                          <a href="http://www.andrewdinsky.com/studiocity/">
                            studio city
                          </a>
                        </li>
                        <li>
                          <a href="http://www.andrewdinsky.com/valleyvillage/">
                            Valley Village
                          </a>
                        </li>
                        <li>
                          <a href="http://www.andrewdinsky.com/tolucalake/">
                            toluca lake
                          </a>
                        </li>
                        <li>
                          <a href="http://www.andrewdinsky.com/westhollywood/">
                            west hollywood
                          </a>
                        </li>
                        <li>
                          <a href="http://www.andrewdinsky.com/shermanoaks/">
                            Sherman Oaks
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://www.andrewdinsky.com/encino/
    "
                          >
                            Encino
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="header-mobile houzez-header-mobile " data-sticky="1">
        <div className="container">
          <div className="mobile-nav">
            <span
              className={isActive ? "nav-trigger mobile-open" : "nav-trigger"}
              onClick={toggleClass}
            >
              <i className="fa fa-navicon"></i>
            </span>
            <div className="nav-dropdown main-nav-dropdown">
              <ul id="main-nav" className="">
                <li
                  id="menu-item-52"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-52"
                >
                  <a
                    className="mobile-menu-link"
                    href="http://www.andrewdinsky.com/meettheteam/"
                  >
                    HOME
                  </a>
                </li>
                <li
                  id="menu-item-49"
                  onClick={toggleProperties}
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-49 has-child"
                >
                  <a className="mobile-menu-link" href="#">
                    PROPERTIES
                  </a>
                  <ul
                    className="sub-menu"
                    style={{ display: propertiesToggle ? "block" : "none" }}
                  >
                    <li
                      id="menu-item-667"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-667"
                    >
                      <a href="http://www.andrewdinsky.com/properties/property-search/">
                        Property Search
                      </a>
                    </li>
                    <li
                      id="menu-item-655"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-655"
                    >
                      <a href="http://www.andrewdinsky.com/homes-for-sale-featured/">
                        Current Listings
                      </a>
                    </li>
                    <li
                      id="menu-item-598"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-598"
                    >
                      <a href="http://www.andrewdinsky.com/homes-for-sale-toppicks/Open-Houses/946768">
                        Open Houses
                      </a>
                    </li>
                    <li
                      id="menu-item-667"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-667"
                    >
                      <a href="http://www.andrewdinsky.com/sold-featured-listing/?sortBy=soldDate">
                        Sold Properties
                      </a>
                    </li>
                    <li
                      id="menu-item-655"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-655"
                    >
                      <a href="http://www.andrewdinsky.com/properties/pocket-listings/">
                        Pocket Listings
                      </a>
                    </li>
                  </ul>
                  <span className="expand-me"></span>
                </li>
                <li
                  onClick={toggleCommunities}
                  id="menu-item-72"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-72"
                >
                  <a className="mobile-menu-link" href="#">
                    COMMUNITIES
                  </a>
                  <ul
                    className="sub-menu"
                    style={{ display: communitiesToggle ? "block" : "none" }}
                  >
                    <li
                      id="menu-item-24524"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24524"
                    >
                      <a href="http://www.andrewdinsky.com/communities/">
                        ALL COMMUNITIES
                      </a>
                    </li>
                    <li
                      id="menu-item-24524"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24524"
                    >
                      <a href="http://www.andrewdinsky.com/valleyvillage/">
                        Valley Village
                      </a>
                    </li>
                    <li
                      id="menu-item-24524"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24524"
                    >
                      <a href="http://www.andrewdinsky.com/shermanoaks/">
                        Sherman Oaks
                      </a>
                    </li>
                    <li
                      id="menu-item-24524"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24524"
                    >
                      <a href="http://www.andrewdinsky.com/studiocity/">
                        Studio City
                      </a>
                    </li>
                    <li
                      id="menu-item-24524"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24524"
                    >
                      <a href="http://www.andrewdinsky.com/tolucalake/">
                        Toluca Lake
                      </a>
                    </li>
                    <li
                      id="menu-item-24524"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24524"
                    >
                      <a href="http://www.andrewdinsky.com/encino/">Encino</a>
                    </li>
                    <li
                      id="menu-item-24524"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24524"
                    >
                      <a href="http://www.andrewdinsky.com/westhollywood/">
                        West Hollywood
                      </a>
                    </li>
                  </ul>
                  <span className="expand-me"></span>
                </li>
                <li
                  id="menu-item-618"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-618 has-child"
                >
                  <a
                    className="mobile-menu-link"
                    href="http://www.andrewdinsky.com/about__trashed/reviews/"
                  >
                    TESTIMONIALS
                  </a>
                </li>
                <li
                  id="menu-item-65"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-65"
                >
                  <a
                    className="mobile-menu-link"
                    href="http://www.andrewdinsky.com/category/blog/"
                  >
                    BLOG
                  </a>
                </li>
                <li
                  onClick={toggleVideos}
                  id="menu-item-605"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-605"
                >
                  <a className="mobile-menu-link" href="#">
                    VIDEOS
                  </a>
                  <ul
                    className="sub-menu"
                    style={{ display: videosToggle ? "block" : "none" }}
                  >
                    <li
                      id="menu-item-540"
                      className="menu-item menu-item-type-post_type menu-item-object-hji_areas menu-item-540"
                    >
                      <a href="http://www.andrewdinsky.com/top-3-picks-of-the-week/">
                        Top 3 Picks
                      </a>
                    </li>
                    <li
                      id="menu-item-539"
                      className="menu-item menu-item-type-post_type menu-item-object-hji_areas menu-item-539"
                    >
                      <a href="http://www.andrewdinsky.com/property-videos/">
                        Property Tours
                      </a>
                    </li>
                    <li
                      id="menu-item-560"
                      className="menu-item menu-item-type-post_type menu-item-object-hji_areas menu-item-560"
                    >
                      <a href="http://www.andrewdinsky.com/3d-virtual-tours/">
                        3D Virtual Tours
                      </a>
                    </li>
                  </ul>
                  <span className="expand-me"></span>
                </li>
                <li
                  id="menu-item-24521"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24521"
                >
                  <a
                    className="mobile-menu-link"
                    href="https://www.jwteam.com/blog"
                  >
                    CONTACT
                  </a>
                </li>

                <li
                  id="menu-item-51"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-51"
                >
                  <a
                    className="mobile-menu-link"
                    href="https://www.jwteam.com/sell"
                  >
                    LISTING PROCESS
                  </a>
                </li>
              </ul>{" "}
            </div>
          </div>
          <div className="header-logo logo-mobile">
            <a href="https://www.therealexperts.com/">
              <img
                width="414"
                height="69"
                alt="ANDREW DINSKY - Andrew Dinsky"
                title="ANDREW DINSKY"
                data-src="http://www.andrewdinsky.com/wp-content/uploads/2018/05/DINSKYTEAMLOGO2018-1-e1525201513551.png"
                className="logo-img lazyloaded"
                src={require("../../images/DINSKYTEAMLOGO2018.png")}
              />
            </a>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

HeaderContainer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.any,
  }),
};

HeaderContainer.defaultProps = { location: { pathname: "" } };

export default memo(HeaderContainer);
