import cs from "classnames";
import React, { memo } from "react";
import "./hero-container.scss";

const HeroContainer = (props) => {
  let { openModalHandler } = props;

  return (
    <div className={cs("on-hero")}>
      <div class="section" id="hero">
        <picture>
          <source
            srcset={require("../../images/hero-header.png")}
            type="image/png"
          />
          <img
            alt="Modern home"
            src="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/trade-in-hero-desktop-2x-d43badaa34974c508f3ba3c032fa2774af60e9e262d4ed15e001cb5d81bd9c9b.jpg"
          />
        </picture>
        <div className="content">
          <div className="intro-box">
            <h1>Now, You Can Buy Before You Sell</h1>
            <p className="hero-under-header"></p>
            <p className="hero-sub-header">
              Powered by HomeLight Trade-In<sup>TM</sup>
            </p>
            <br/>
            <p className="hero-description">
              A calmer, more certain alternative to real estate.
              Avoid the stress, risk, and hassle of buying and selling at the same time.
              HomeLight will buy your current home giving you the freedom to buy your new home.
            </p>
            <a
              className="button hero-button"
              href="#"
              onClick={() => openModalHandler(true)}
            >
              <p>START HERE</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(HeroContainer);
