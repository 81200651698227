import axios from "axios";
import moment from "moment";

const NETLIFY_SUBMIT_FORM = "/.netlify/functions/contact-form-submit";
const NETLIFY_EMAIL_NOTIFICATION = "/.netlify/functions/email-notification";

export const submitContactForm = (contactData) => {
  contactData["Timestamp"] = moment().format("MMMM Do YYYY, h:mm:ss a");
  //console.log('submitContactForm', contactData)

  return Promise.all([
    axios.post(NETLIFY_SUBMIT_FORM, contactData),
    axios.post(NETLIFY_EMAIL_NOTIFICATION, contactData),
  ]);
};
