import React, { memo } from "react";
import "./footer-container.scss";

const FooterContainer = () => {
  return (
    <div className="on-footer">
      <div id="footer" className="wrap">
        <div className="footer_container">
          <div className="menu-footer-container">
            <ul id="menu-footer" className="footernav">
              <li
                id="menu-item-383"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-383"
              >
                <a href="http://www.andrewdinsky.com/meettheteam/">About</a>
              </li>
              <li
                id="menu-item-346"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-346"
              >
                <a href="http://www.andrewdinsky.com/properties/property-search/">
                  Properties
                </a>
              </li>
              <li
                id="menu-item-1508"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1508"
              >
                <a href="http://www.andrewdinsky.com/about__trashed/reviews/">
                  Testimonials
                </a>
              </li>
              <li
                id="menu-item-1501"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1501"
              >
                <a href="http://www.andrewdinsky.com/communities/">
                  Communities
                </a>
              </li>
              <li
                id="menu-item-1885"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1885"
              >
                <a href="http://www.andrewdinsky.com/top-3-picks-of-the-week/">
                  Videos
                </a>
              </li>
              <li
                id="menu-item-408"
                className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-408"
              >
                <a href="http://www.andrewdinsky.com/category/blog/">Blog</a>
              </li>
              <li
                id="menu-item-349"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-349"
              >
                <a href="http://www.andrewdinsky.com/contact/">Contact</a>
              </li>
            </ul>
          </div>
          <div className="ftr_credits">
            © 2021{" "}
            <a
              style={{ textDecoration: "underline;" }}
              href="http://www.andrewdinsky.com"
            >
              Andrewdinsky.com
            </a>{" "}
            All rights reserved.{" "}
            <a href="http://www.andrewdinsky.com/sitemap/">Sitemap</a> |{" "}
            <a href="http://www.andrewdinsky.com/wp-admin/">Login</a>{" "}
          </div>

          <div className="ftr_logo">
            <img
              alt="MLS, Equal Housing, Realtor - Andrew Dinsky"
              title="MLS, Equal Housing, Realtor"
              style={{ width: "105px", height: "22px" }}
              data-src="http://www.andrewdinsky.com/wp-content/themes/andrewdinsky.com/images/footer-logo.png"
              className=" lazyloaded"
              src={require("../../images/footer-logo.png")}
            />

            <img
              alt="KW Luxury International - Andrew Dinsky"
              title="KW Luxury International"
              data-src="http://www.andrewdinsky.com/wp-content/themes/andrewdinsky.com/images/kw-luxury-logo.png"
              className="site-logo lazyloaded"
              src={require("../../images/kw-luxury-logo.png")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(FooterContainer);
