import cs from "classnames";
import React, { memo, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import "./testimonials-container.scss";

const windowGlobal = typeof window !== "undefined" && window;

function PrevArrow(props) {
  const { className, style, onClick, currentSlide } = props;
  return (
    <div className="slick-slider slick-initialized" dir="ltr" onClick={onClick}>
      <div
        className={`slick-arrow slick-prev ${
          currentSlide === 0 ? "slick-disabled" : ""
        }`}
        style={{ display: "flex" }}
        onClick={onClick}
      >
        <div className="icon-wrap">
          <svg className="chevron-icon-component left" viewBox="0 0 13 8">
            <path d="M5.66014276,7.16797312 L0.355467902,1.86329827 C0.227863901,1.73569426 0.164062109,1.58074699 0.164062109,1.39845562 C0.164062109,1.21616425 0.227863901,1.06121698 0.355467902,0.933612982 L0.957028968,0.332051916 C1.08463297,0.204447915 1.23958024,0.136088703 1.42187161,0.12697428 C1.60416298,0.117859857 1.75911025,0.17710423 1.88671425,0.304708231 L6.1249854,4.54297938 L10.3632565,0.304708231 C10.4908605,0.17710423 10.6458078,0.117859857 10.8280992,0.12697428 C11.0103906,0.136088703 11.1653378,0.204447915 11.2929418,0.332051916 L11.8945029,0.933612982 C12.0221069,1.06121698 12.0859087,1.21616425 12.0859087,1.39845562 C12.0859087,1.58074699 12.0221069,1.73569426 11.8945029,1.86329827 L6.58982804,7.16797312 C6.46222404,7.31380596 6.30727677,7.3867226 6.1249854,7.3867226 C5.94269403,7.3867226 5.78774676,7.31380596 5.66014276,7.16797312 Z"></path>
          </svg>
        </div>
      </div>
    </div>
  );
}

function NextArrow(props) {
  const { className, style, onClick, currentSlide, slideCount } = props;
  return (
    <div
      className={`slick-arrow slick-next ${
        currentSlide === slideCount - getShownSlideCount()
          ? "slick-disabled"
          : ""
      }`}
      style={{ display: "flex" }}
      onClick={onClick}
    >
      <div className="icon-wrap">
        <svg className="chevron-icon-component right" viewBox="0 0 13 8">
          <path d="M5.66014276,7.16797312 L0.355467902,1.86329827 C0.227863901,1.73569426 0.164062109,1.58074699 0.164062109,1.39845562 C0.164062109,1.21616425 0.227863901,1.06121698 0.355467902,0.933612982 L0.957028968,0.332051916 C1.08463297,0.204447915 1.23958024,0.136088703 1.42187161,0.12697428 C1.60416298,0.117859857 1.75911025,0.17710423 1.88671425,0.304708231 L6.1249854,4.54297938 L10.3632565,0.304708231 C10.4908605,0.17710423 10.6458078,0.117859857 10.8280992,0.12697428 C11.0103906,0.136088703 11.1653378,0.204447915 11.2929418,0.332051916 L11.8945029,0.933612982 C12.0221069,1.06121698 12.0859087,1.21616425 12.0859087,1.39845562 C12.0859087,1.58074699 12.0221069,1.73569426 11.8945029,1.86329827 L6.58982804,7.16797312 C6.46222404,7.31380596 6.30727677,7.3867226 6.1249854,7.3867226 C5.94269403,7.3867226 5.78774676,7.31380596 5.66014276,7.16797312 Z"></path>
        </svg>
      </div>
    </div>
  );
}

const getShownSlideCount = () =>
  windowGlobal.innerWidth >= 1200
    ? 4
    : Math.floor(windowGlobal.innerWidth / 250);

const TestimonialsContainer = (props) => {
  const [slidesToShow, setSlidesToShow] = useState(getShownSlideCount());

  const settings = {
    dots: true,
    infinite: false,
    speed: 100,
    slidesToShow,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  useEffect(() => {
    const handleResize = () => {
      setSlidesToShow(getShownSlideCount());
    };

    windowGlobal.addEventListener("resize", handleResize);
  });

  return (
    <div className={cs("on-testimonials")}>
      <div className="section testimonials">
        <div className="content">
          <h2>Don’t take our word for it, take theirs.</h2>
        </div>
        <div className="section-media section-video">
          <div className="content">
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <div className="inner">
                  <blockquote>
                    <q>
                      The fact that we're both teachers on teacher incomes, and we were able to use the HomeLight Trade-In program to live in the house of our dreams is pretty incredible.
                    </q>
                    <cite>
                      <strong>Jason Castro</strong>
                      <span>HomeLight Trade-In Client</span>
                    </cite>
                  </blockquote>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 xs-first">
                <div className="video">
                  <div>
                    <div>
                      <div className="placeholder-video frame">
                        <ReactPlayer
                          url="https://player.vimeo.com/video/469098176?autoplay=1"
                          width="100%"
                          height="100%"
                          light={false}
                          controls={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="testimonial-slider">
            <div className="content">
              <Slider {...settings}>
                <div
                  data-index="0"
                  className="slick-slide slick-active slick-current"
                  tabIndex="-1"
                  aria-hidden="false"
                  style={{ outline: "none", width: "293px" }}
                >
                  <div>
                    <div
                      tabIndex="-1"
                      style={{ width: "100%", display: "inline-block" }}
                    >
                      <div className="img-wrap">
                        <picture>
                          <source
                            type="image/webp"
                            srcSet="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/omer-tripp-43b78afe16d0e37777ca2f83a4e1d8c422f6ea9f21dd14a608dc71165852ccb8.webp"
                          />
                          <source
                            type="image/jpg"
                            srcSet="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/omer-tripp-92a1f21db23072652be08fabb81485763ce14c4b2926a9be2a541afcdd3a4370.jpg"
                          />
                          <img
                            className=" lazyloaded"
                            data-src="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/omer-tripp-92a1f21db23072652be08fabb81485763ce14c4b2926a9be2a541afcdd3a4370.jpg"
                            alt="Picture of Omer Tripp"
                            src="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/omer-tripp-92a1f21db23072652be08fabb81485763ce14c4b2926a9be2a541afcdd3a4370.jpg"
                          />
                        </picture>
                      </div>
                      <blockquote>
                        <p>
                          <q>
                          HomeLight Trade-In enabled us to break out of our current budget into a higher budget. Moving into a bigger house with a swimming pool and a huge backyard are definitely things you appreciate every day. I’m pretty confident we would not have ended up here if it weren’t for HomeLight.
                          </q>
                          <br />
                          <a href="https://www.homelight.com/blog/buyer-trade-in-antwerp-lane-house/">
                            Read the full story
                          </a>
                        </p>
                        <cite>
                          <strong>Omer Tripp</strong>
                          <span>HomeLight Trade-In Client</span>
                        </cite>
                      </blockquote>
                    </div>
                  </div>
                </div>
                <div
                  data-index="1"
                  className="slick-slide slick-active"
                  tabIndex="-1"
                  aria-hidden="false"
                  style={{ outline: "none", width: "293px" }}
                >
                  <div>
                    <div
                      tabIndex="-1"
                      style={{ width: "100%", display: "inline-block" }}
                    >
                      <div className="img-wrap">
                        <picture>
                          <source
                            type="image/webp"
                            srcSet="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/lauren-boston-011e36bc057f5dcc80eba907700726e953e2adc8dc2c5ec76bc640cae8cc8e7c.webp"
                          />
                          <source
                            type="image/jpg"
                            srcSet="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/lauren-boston-93bc43d6d3dae32597f1ffcd8a56a5c9f589a85722aed35f9bffc2172d7496ed.jpg"
                          />
                          <img
                            className=" lazyloaded"
                            srcSet="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/lauren-boston-93bc43d6d3dae32597f1ffcd8a56a5c9f589a85722aed35f9bffc2172d7496ed.jpg"
                            alt="Picture of Lauren Boston"
                            src="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/lauren-boston-93bc43d6d3dae32597f1ffcd8a56a5c9f589a85722aed35f9bffc2172d7496ed.jpg"
                          />
                        </picture>
                      </div>
                      <blockquote>
                        <p>
                          <q>
                          Using HomeLight is kind of like shopping on Amazon, you don’t have to go to a bunch of different stores to try to find what you’re looking for, you just make a few clicks and find what you need and you’re done... it’s definitely the biggest change in real estate that I’ve seen in my career.
                          </q>{" "}
                          <br />
                          <a href="https://www.homelight.com/blog/trade-in-levante-street-house/">
                            Read the full story
                          </a>
                        </p>
                        <cite>
                          <strong>Lauren Boston</strong>
                          <span>HomeLight Trade-In Certified Agent</span>
                        </cite>
                      </blockquote>
                    </div>
                  </div>
                </div>
                <div
                  data-index="2"
                  className="slick-slide slick-active"
                  tabIndex="-1"
                  aria-hidden="false"
                  style={{ outline: "none", width: "293px" }}
                >
                  <div>
                    <div
                      tabIndex="-1"
                      style={{ width: "100%", display: "inline-block" }}
                    >
                      <div className="img-wrap">
                        <picture>
                          <source
                            type="image/webp"
                            srcSet="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/eric-nicole-tilford-92bec948118ac324051dd37ea7599553d7d64a39bd9234df63633abb48c318a4.webp"
                          />
                          <source
                            type="image/jpg"
                            srcSet="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/eric-nicole-tilford-d09ded382fed8a7b2f0af53d575e54e89821f56dcd501db1f51ea9fd0d5e8488.jpg"
                          />
                          <img
                            className=" lazyloaded"
                            data-src="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/eric-nicole-tilford-d09ded382fed8a7b2f0af53d575e54e89821f56dcd501db1f51ea9fd0d5e8488.jpg"
                            alt="Picture of Eric and Nicole Tilford"
                            src="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/eric-nicole-tilford-d09ded382fed8a7b2f0af53d575e54e89821f56dcd501db1f51ea9fd0d5e8488.jpg"
                          />
                        </picture>
                      </div>
                      <blockquote>
                        <p>
                          <q>
                          If there was an easy button in real estate transactions, this would be it. We were able to buy our perfect home because we didn't have to wait to list our house and sell it to have a bona fide offer.
                          </q>{" "}
                          <br />
                          <a href="https://www.homelight.com/blog/trade-in-cross-country-house/">
                            Read the full story
                          </a>
                        </p>
                        <cite>
                          <strong>Eric and Nicole Tilford</strong>
                          <span>HomeLight Trade-In Clients</span>
                        </cite>
                      </blockquote>
                    </div>
                  </div>
                </div>
                <div
                  data-index="3"
                  className="slick-slide slick-active"
                  tabIndex="-1"
                  aria-hidden="false"
                  style={{ outline: "none", width: "293px" }}
                >
                  <div>
                    <div
                      tabIndex="-1"
                      style={{ width: "100%", display: "inline-block" }}
                    >
                      <div className="img-wrap">
                        <picture>
                          <source
                            type="image/webp"
                            srcSet="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/kelli-griggs-0ffb6bf3e153cdc7f4e90166c33ae1d9339ee3830646639e5c2143c2bb18a60b.webp"
                          />
                          <source
                            type="image/jpg"
                            srcSet="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/kelli-griggs-a4b1ade4a5666ca8f44170e466f85942fe6ec92cfb880a0cfc4bbc4fbb2aeb52.jpg"
                          />
                          <img
                            className=" lazyloaded"
                            data-src="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/kelli-griggs-a4b1ade4a5666ca8f44170e466f85942fe6ec92cfb880a0cfc4bbc4fbb2aeb52.jpg"
                            alt="Picture of Kelli Griggs"
                            src="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/kelli-griggs-a4b1ade4a5666ca8f44170e466f85942fe6ec92cfb880a0cfc4bbc4fbb2aeb52.jpg"
                          />
                        </picture>
                      </div>
                      <blockquote>
                        <p>
                          <q>
                          The HomeLight Trade-In product is probably the greatest thing that has come along in the last, I think 12 years in my opinion. I think the major benefits of using HomeLight Trade-In is to give your seller the option to be able to buy at the same time that they're selling.
                          </q>{" "}
                          <br />
                          <a href="https://www.homelight.com/blog/trade-in-cross-country-house/">
                            Read the full story
                          </a>
                        </p>
                        <cite>
                          <strong>Kelli Griggs</strong>
                          <span>HomeLight Trade-In Certified Agent</span>
                        </cite>
                      </blockquote>
                    </div>
                  </div>
                </div>
                <div
                  data-index="4"
                  className="slick-slide"
                  tabIndex="-1"
                  aria-hidden="true"
                  style={{ outline: "none", width: "293px" }}
                >
                  <div>
                    <div
                      tabIndex="-1"
                      style={{ width: "100%", display: "inline-block" }}
                    >
                      <div className="img-wrap">
                        <picture>
                          <source
                            type="image/webp"
                            srcSet="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/laura-gottlieb-00cfd425563852e12c1622d54ec1ec215da5beb639af7b036d7b556f9f49a2f0.webp"
                          />
                          <source
                            type="image/jpg"
                            srcSet="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/laura-gottlieb-0d768bb4b6dd33959a99ca1d01f88f61d3b1ef35f324bf13266ced47490b5696.jpg"
                          />
                          <img
                            className=" lazyloaded"
                            srcSet="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/laura-gottlieb-0d768bb4b6dd33959a99ca1d01f88f61d3b1ef35f324bf13266ced47490b5696.jpg"
                            alt="Picture of Laura Gottlieb"
                            src="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/laura-gottlieb-0d768bb4b6dd33959a99ca1d01f88f61d3b1ef35f324bf13266ced47490b5696.jpg"
                          />
                        </picture>
                      </div>
                      <blockquote>
                        <p>
                          <q>
                          The HomeLight Trade-In process only took a few weeks! It was a real estate miracle — and exactly what I needed to facilitate my transition of moving to a new home, state, and job in less than six weeks!!
                          </q>{" "}
                          <br />
                        </p>
                        <cite>
                          <strong>Laura Gottlieb</strong>
                          <span>HomeLight Trade-In Client</span>
                        </cite>
                      </blockquote>
                    </div>
                  </div>
                </div>
                <div
                  data-index="5"
                  className="slick-slide"
                  tabIndex="-1"
                  aria-hidden="true"
                  style={{ outline: "none", width: "293px" }}
                >
                  <div>
                    <div
                      tabIndex="-1"
                      style={{ width: "100%", display: "inline-block" }}
                    >
                      <div className="img-wrap">
                        <picture>
                          <source
                            type="image/webp"
                            srcSet="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/williamson-family-2d078461d8813d7564729278317fa275d8cc433655cdba52eaf665817867423b.webp"
                          />
                          <source
                            type="image/jpg"
                            srcSet="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/williamson-family-3060a13f4883b107ef06177418f4c67a34ff62bf1a9f4ef3ec5440cc09a1fbcd.jpg"
                          />
                          <img
                            className=" lazyloaded"
                            data-src="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/williamson-family-3060a13f4883b107ef06177418f4c67a34ff62bf1a9f4ef3ec5440cc09a1fbcd.jpg"
                            alt="Picture of Suzanne Williamson"
                            src="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/williamson-family-3060a13f4883b107ef06177418f4c67a34ff62bf1a9f4ef3ec5440cc09a1fbcd.jpg"
                          />
                        </picture>
                      </div>
                      <blockquote>
                        <p>
                          <q>
                          The idea of getting a home ready to sell while you live there is overwhelming. HomeLight Trade-In removes that stress and allows potential buyers complete flexibility to view your home at their convenience. By vacating and staging our home after we moved out, we were able to sell our home for more money.
                          </q>{" "}
                          <br />
                          <a href="https://www.homelight.com/blog/trade-in-sitio-granado-house/">
                            Read the full story
                          </a>
                        </p>
                        <cite>
                          <strong>Suzanne Williamson</strong>
                          <span>HomeLight Trade-In Client</span>
                        </cite>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(TestimonialsContainer);
