import React, { memo, useRef, useState } from "react";
import Modal from "react-modal";
import queryString from "query-string";
import "./contact-modal-container.scss";

import useSiteMetadata from "../../api/graphql/siteMetadata";

const ContactModalContainer = (props) => {
  const query = queryString.parse(props.location.search);

  let site = useSiteMetadata().title;

  const [termsAccepted, setTermsAccepted] = useState(true);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [sentStatus, setSentStatus] = useState("new"); // "new", "sending", "sent", "failed"
  const form = useRef(null);

  let { isOpen } = props;
  let { openModalHandler } = props;
  let { submitContactForm } = props;

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
  };

  const getFormData = () => {
    return {
      Firstname: firstName,
      Lastname: lastName,
      Phone: phone,
      Email: email,
      Site: site,
      utm_medium: query && query.utm_medium ? query.utm_medium : "",
      utm_source: query && query.utm_source ? query.utm_source : "",
      utm_content: query && query.utm_content ? query.utm_content : "",
      utm_term: query && query.utm_term ? query.utm_term : "",
      utm_campaign: query && query.utm_campaign ? query.utm_campaign : "",
    };
  };

  const closeForm = () => {
    setSentStatus("new");
    openModalHandler(false);
  };

  const validateData = () => {
    return form.current.reportValidity();
  };

  const submitForm = () => {
    let data = getFormData();
    const validated = validateData();
    if (validated) {
      setSentStatus("sending");
      setTimeout(() => {
        submitContactForm(data)
          .then((res) => {
            if (res.filter((r) => r.status !== 200).length > 0) {
              setSentStatus("failed");
            } else {
              setSentStatus("sent");
              clearForm();
            }
          })
          .catch((err) => {
            setSentStatus("failed");
            console.log(err);
          });
      }, 1000);
    }
  };

  return (
    isOpen && (
      <main className="cash-close-page">
        <Modal
          className="modal-dialog on-ac-rm-modal"
          isOpen={isOpen}
          ariaHideApp={false}
        >
          <div className="modal" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  aria-label="Close"
                  className="close agent-intro-complete-btn close-btn"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => openModalHandler(false)}
                >
                  <span area-hidden="true">Close</span>
                </button>
              </div>
              {sentStatus === "new" && (
                <div className="modal-body">
                  <div className="modal-title">Contact Us</div>
                  <br />
                  <div>
                    <section
                      className="component-demo generic-form-demo"
                      id="cash-close-signup-form"
                    >
                      <form ref={form} className="generic-form">
                        <div className="row">
                          <div className="col-xs-12 col-md-6">
                            <label for="input-first-name">First name</label>
                            <input
                              id="input-first-name"
                              className="first-name-input"
                              type="text"
                              placeholder="Enter your first name"
                              required={true}
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                          </div>
                          <div className="col-xs-12 col-md-6">
                            <label for="input-last-name">Last name</label>
                            <input
                              id="input-last-name"
                              className="last-name-input"
                              type="text"
                              placeholder="Enter your last name"
                              col
                              required={true}
                              onChange={(e) => setLastName(e.target.value)}
                            />
                          </div>
                          <div className="col-xs-12 col-md-6">
                            <label for="input-email">Email</label>
                            <input
                              id="input-email"
                              className="email-input"
                              type="email"
                              placeholder="Enter your email"
                              required={true}
                              pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div className="col-xs-12 col-md-6">
                            <label for="input-phone">Phone number</label>
                            <input
                              id="input-phone"
                              className="phone-input"
                              type="tel"
                              placeholder="Enter your phone number"
                              required={true}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </div>
                          <div className="col-xs-12 col-md-12">
                            <input
                              id="input-terms"
                              type="checkbox"
                              checked={termsAccepted}
                              onClick={() => setTermsAccepted(!termsAccepted)}
                            ></input>
                            <label for="input-terms" className="terms-label">
                              I have read and accept{" "}
                              <a href="https://www.homelight.com/terms">
                                Terms and Conditions
                              </a>
                              .
                            </label>
                          </div>
                        </div>
                      </form>
                      <button
                        disabled={!termsAccepted}
                        className="button agent-intro-complete-btn"
                        id="contact-form-submit-btn"
                        data-submit="true"
                        onClick={submitForm}
                      >
                        Submit
                      </button>
                    </section>
                  </div>
                </div>
              )}
              {sentStatus === "sending" && (
                <div className="modal-body align-center">
                  <h1>Sending...</h1>
                </div>
              )}
              {sentStatus === "sent" && (
                <div className="modal-body align-center">
                  <h1>Thank you!</h1>
                  <p>We received your inquiry and will contact you shortly.</p>
                  <button
                    className="button thankyou-btn"
                    id="contact-form-submit-btn"
                    data-submit="true"
                    onClick={closeForm}
                  >
                    Close
                  </button>
                </div>
              )}
              {sentStatus === "failed" && (
                <div className="modal-body align-center">
                  <h1>Error sending message</h1>
                  <p>
                    Sorry, your message may not have been sent correctly. Please
                    try again or contact us directly by email.
                  </p>
                  <button
                    className="button thankyou-btn"
                    id="contact-form-submit-btn"
                    data-submit="true"
                    onClick={closeForm}
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          </div>
        </Modal>
      </main>
    )
  );
};

export default memo(ContactModalContainer);
